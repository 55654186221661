<template>
  <v-app>
    <div>
      <form class="form" @submit.stop.prevent="formOnsubmit()">
        <!--begin: Wizard Step 1-->
        <div
          class="pb-5"
          data-wizard-type="step-content"
          data-wizard-state="current"
        >
          <h5 class="font-weight-bolder text-dark font-size-h6 font-size-h3-lg">
            <span>
              {{ title }}
            </span>
          </h5>

          <b-form-group
            id="input-group-name"
            label="Nama:"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="form.name"
              placeholder="Nama"
            ></b-form-input>
            <small class="text-danger">{{ error.name }}</small>
          </b-form-group>

          <b-form-group id="input-group-start-time">
            <label for="input-start-time">Mulai: </label>
            <v-menu
              ref="menuDate1"
              v-model="menuDate1"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.start_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-start-time"
                  v-model="form.start_time"
                  placeholder="Mulai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-time-picker
                v-if="menuDate1"
                v-model="form.start_time"
                full-width
                format="24hr"
                @click:minute="$refs.menuDate1.save(form.start_time)"
              >
              </v-time-picker>
            </v-menu>
            <small class="text-danger">{{ error.start_time }}</small>
          </b-form-group>

          <b-form-group id="input-group-end-time">
            <label for="input-end-time">Selesai: </label>
            <v-menu
              ref="menuDate2"
              v-model="menuDate2"
              :close-on-content-click="false"
              :nudge-right="40"
              :return-value.sync="form.end_time"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <b-form-input
                  id="input-end-time"
                  v-model="form.end_time"
                  placeholder="Selesai"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></b-form-input>
              </template>
              <v-time-picker
                v-if="menuDate2"
                v-model="form.end_time"
                full-width
                format="24hr"
                @click:minute="$refs.menuDate2.save(form.end_time)"
              ></v-time-picker>
            </v-menu>
            <small class="text-danger">{{ error.end_time }}</small>
          </b-form-group>

          <b-form-group id="input-group-description">
            <label for="input-description"
              >Keterangan: <em class="text-muted">opsional</em></label
            >
            <b-form-textarea
              id="input-description"
              v-model="form.description"
              placeholder="Keterangan"
              rows="4"
              max-rows="8"
            ></b-form-textarea>
            <small class="text-danger">{{ error.description }}</small>
          </b-form-group>
        </div>
        <!--end: Wizard Step 1-->

        <!--begin: Wizard Actions -->
        <div class="d-flex justify-content-between border-top pt-10">
          <div>
            <b-button type="submit" variant="primary">Simpan</b-button>
            <b-button
              type="button"
              class="ml-2"
              variant="default"
              @click="$router.push('/masters/times/list')"
            >
              Batal
            </b-button>
          </div>
        </div>
        <!--end: Wizard Actions -->
      </form>
    </div>
  </v-app>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    title: String,
  },
  data() {
    return {
      error: {
        name: "",
        description: "",
        start_time: "",
        end_time: "",
      },
      menuDate1: false,
      menuDate2: false,
    };
  },
  methods: {
    async formOnsubmit() {
      if (this.form.start_time >= this.form.end_time) {
        this.error.end_time = "Waktu selesai harus lebih lama dari waktu mulai";
        throw new Error("Error");
      } else {
        this.error.end_time = "";
        // Make Request
        let response = await module.submit(this.form, this.route);
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire({
            title: response.success.title,
            text: response.success.message,
            icon: "success",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$router.push("/masters/times/list");
        }
      }
    },
  },
};
</script>